import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Container, Typography } from '@mui/material';
import { FarmData } from 'library/models/farm';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import GuardComponent from '../../auth/components/GuardComponent';
import { roleTypes } from '../../auth/roleTypes';
import LoadingScreen from '../../components/loading-screen/LoadingScreen';
import { useGetCentersQuery } from '../../customers/customerApi';
import Page404 from '../../pages/Page404';
import { useGetFarmsQuery } from '../store/famsApi';
import FarmDialog from './FarmDialog';

const FarmList = () => {
  const { data: farms, isLoading, isError } = useGetFarmsQuery();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [selectedFarm, setSelectedFarm] = useState<FarmData | null>(null);
  const { data: centers, isLoading: isLoadingCenters } = useGetCentersQuery();

  const handleUpdate = (farm: FarmData) => {
    setSelectedFarm(farm);
    setIsDialogOpen(true);
  };

  const columns = useMemo<MRT_ColumnDef<FarmData>[]>(
    () => [
      {
        header: t('table.header.farm.name'),
        accessorKey: 'farm_name',
        accessor: 'farm_name',
      },
      {
        header: t('table.header.farm.address'),
        accessorKey: 'address',
        accessor: 'address',
      },
      {
        header: t('table.header.farm.zipCode'),
        accessorKey: 'zip_code',
        accessor: 'zip_code',
      },
      {
        header: t('table.header.farm.town'),
        accessorKey: 'municipality',
        accessor: 'municipality',
      },
      {
        header: t('table.header.farm.country'),
        accessorKey: 'country',
        accessor: 'country',
      },
      {
        header: t('table.header.farm.actions'),
        accessorKey: 'actions',
        accessor: 'actions',
        accessorFn: (row) => row,
        Cell: (data) => (
          <GuardComponent allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR]}>
            <Button onClick={() => handleUpdate(data.row.original)}>
              <EditIcon />
            </Button>
          </GuardComponent>
        ),
      },
    ],
    []
  );

  if (isLoading || !farms) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <Page404 />;
  }

  const handleClose = () => {
    setIsDialogOpen(false);
    setSelectedFarm(null);
  };

  return (
    <Container>
      <Helmet>
        <title>{t('farms.title')}</title>
      </Helmet>
      <Typography variant='h3'>{t('farms.title')}</Typography>
      <Box m={2} display={'flex'} flexDirection={'row'} justifyContent={'right'}>
        <GuardComponent allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR]}>
          <Box>
            <Button onClick={() => setIsDialogOpen(true)} variant='contained' color='primary'>
              {t('button.add.farm')}
            </Button>
          </Box>
        </GuardComponent>
      </Box>
      {isDialogOpen && (
        <FarmDialog isOpen={isDialogOpen} onClose={handleClose} farm={selectedFarm} />
      )}
      <MaterialReactTable
        columns={columns}
        data={farms || []}
        enableFullScreenToggle={false}
        localization={MRT_Localization_FR}
      />
    </Container>
  );
};

export default FarmList;
