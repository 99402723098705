import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_PLOTS_FRONT,
  FILTERS,
  GEOMETRY,
  GROUPS,
  PATH_PLOT_ORDER_BY_DATE,
  VINTAGES,
} from 'library/constantes/path';
import { Geometry } from 'library/models/geometry';
import { PlotData, PlotGroup } from 'library/models/plot';
import { PlotCreation } from 'library/models/plotCreation.request';
import { authPrepareHeaders } from '../../auth/store/AuthPrepareHeaders';
import { getApiBaseUrl } from '../../config-global';

export const getPlots = () => BASE_PLOTS_FRONT;
export const createPlots = () => BASE_PLOTS_FRONT;
export const getPlotById = (id: string) => `${BASE_PLOTS_FRONT}/${id}`;
export const updatePlotById = (id: string) => `${BASE_PLOTS_FRONT}/${id}`;
export const getPlotVintage = () => `${BASE_PLOTS_FRONT}/${VINTAGES}`;
export const getPlotGroupByPlotId = (id: string) => `${BASE_PLOTS_FRONT}/${id}/${GROUPS}`;
export const getGeometryByPlotId = (id: string) => `${BASE_PLOTS_FRONT}/${id}/${GEOMETRY}`;
export const getPlotByFilter = (filter: string) => `${BASE_PLOTS_FRONT}/${FILTERS}${filter}`;
export const getPlotsOrberByDate = (filter: string) =>
  `${BASE_PLOTS_FRONT}${PATH_PLOT_ORDER_BY_DATE}?${filter}`;

type renewPlots = {
  plots: string[];
  vintage: string;
};

type CheckExist = {
  vintage: string;
  internal_plot_code: string;
  external_plot_code: string;
  customer_id: string;
};

export const plotApi = createApi({
  tagTypes: ['plots', 'farms'],
  reducerPath: 'plotApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(),
    prepareHeaders: authPrepareHeaders,
  }),
  endpoints: (builder) => ({
    checkExist: builder.query<
      {
        internal_code: boolean;
        external_code: boolean;
      },
      CheckExist
    >({
      query: (body) => ({
        url: 'plots/check-exist',
        method: 'GET',
        params: body,
      }),
    }),
    getPlot: builder.query<PlotData, string>({
      providesTags: ['plots'],
      query: (id) => getPlotById(id),
    }),
    getplotGroupByPlotId: builder.query<PlotGroup[], string>({
      query: (id) => getPlotGroupByPlotId(id),
      providesTags: ['farms', 'plots'],
    }),
    getGeometryByPlotId: builder.query<Geometry, string>({
      query: (id) => getGeometryByPlotId(id),
      providesTags: ['farms', 'plots'],
    }),
    getVintagesInPlots: builder.query<number[], void>({
      query: () => getPlotVintage(),
    }),
    getPlots: builder.query<PlotData[], void>({
      query: () => getPlots(),
    }),
    getPlotsOrderByDate: builder.query<PlotData[], string>({
      query: (filter) => {
        return {
          providesTags: ['plots'],
          url: getPlotsOrberByDate(filter),
          method: 'GET',
        };
      },
      providesTags: ['plots'],
    }),
    getPlotByFilter: builder.query<PlotData[], string>({
      query: (filter) => {
        return {
          providesTags: ['plots'],
          url: getPlotByFilter(filter),
          method: 'GET',
        };
      },
      providesTags: ['plots'],
    }),
    createPlot: builder.mutation<PlotData, Partial<PlotCreation>>({
      query: (body) => ({
        url: createPlots(),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['plots'],
    }),
    updatePlot: builder.mutation<PlotData, Partial<PlotCreation>>({
      query: (body) => ({
        url: updatePlotById(body.plot?.plot_id ?? ''),
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['plots'],
    }),
    renewPlots: builder.mutation<string[], Partial<renewPlots>>({
      query: (body) => ({
        url: 'plots/renew',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['plots'],
    }),
    renewPlotsCount: builder.mutation<{ data: number }, Partial<renewPlots>>({
      query: (body) => ({
        url: 'plots/renew',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetPlotQuery,
  useGetPlotsQuery,
  useCreatePlotMutation,
  useGetPlotByFilterQuery,
  useRenewPlotsMutation,
  useGetVintagesInPlotsQuery,
  useCheckExistQuery,
  useGetplotGroupByPlotIdQuery,
  useGetGeometryByPlotIdQuery,
  useUpdatePlotMutation,
  useGetPlotsOrderByDateQuery,
  useRenewPlotsCountMutation,
} = plotApi;
