import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { FeatureCollection } from '@turf/turf';
import { Center, CustomerData } from 'library/models/customer';
import { FarmData, FarmDataCreation } from 'library/models/farm';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MatGeocoder from 'react-mui-mapbox-geocoder';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCentersQuery, useGetCustomersQuery } from '../../customers/customerApi';
import { getCustomerDisplayName } from '../../customers/services/CustomerHelper';
import { RootState } from '../../redux/store';
import { useCreateFarmMutation, useGetFarmsQuery, useUppdateFarmMutation } from '../store/famsApi';
import { deleteName, setName } from '../store/slice';

type Props = {
  onClose?: () => void;
  onCloseFromPlotCreation?: (farm: FarmData | undefined, cancel?: boolean) => void;
  isOpen: boolean;
  farm?: FarmDataCreation | null;
  customerId?: string | null;
  centerProps?: Center | null;
};

const FarmDialog = (props: Props) => {
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';
  const dispatch = useDispatch();
  const { data: centers } = useGetCentersQuery();
  const { name } = useSelector((state: RootState) => state.createFarmSlice);
  const { t } = useTranslation();
  const { onClose, isOpen } = props;
  const [skip, setSkip] = useState<boolean>(true);
  const {
    data: farms,
    isLoading: isLoadingFarms,
    isSuccess: isSuccessFarm,
  } = useGetFarmsQuery(undefined, {
    skip,
  });

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { data: customers } = useGetCustomersQuery();
  const [isSnackbarError, setIsSnackbarError] = useState<boolean>(false);
  const [center, setCenter] = useState<Center | undefined>(
    props.centerProps ? props.centerProps : undefined
  );

  const [isSnackBarSuccess, setSnackBarSuccess] = useState<boolean>(false);
  const [farmPayload, setFarmPayload] = useState<FarmDataCreation>({
    farm_name: name ?? props.farm?.farm_name ?? '',
    customer_id: props.customerId ?? '',
    active: true,
    address: '',
    address_other: '',
    zip_code: '',
    municipality: '',
    country: '',
  });
  const [createFarm, { isError, isSuccess, data }] = useCreateFarmMutation();
  const [updateFarm, { isError: isUpdateError, isSuccess: isUpdateSuccess }] =
    useUppdateFarmMutation();

  useEffect(() => {
    if (data && props.onCloseFromPlotCreation) {
      props.onCloseFromPlotCreation(data as FarmData, false);
    }
  }, [data]);

  const geocoderApiOptions = {
    country: 'fr',
    types: 'place',
  };

  const setAddressInformation = async (result: MapboxGeocoder.Result) => {
    result.text;
    const longitude = result.geometry.coordinates[0];
    const latitude = result.geometry.coordinates[1];
    const mapboxReverseGeocodingResult = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&access_token=${MAPBOX_TOKEN}`
    );
    const mapboxReverseGeocodingResultData: FeatureCollection =
      await mapboxReverseGeocodingResult.json();
    if (mapboxReverseGeocodingResultData.features.length > 0) {
      const feature = mapboxReverseGeocodingResultData.features[0] as MapboxGeocoder.Result;
      const zipcode = feature.context.find((context) => context.id.includes('postcode'))?.text;
      const country = feature.context
        .find((context) => context.id.includes('country'))
        ?.short_code?.toUpperCase();

      setFarmPayload({
        ...farmPayload,
        municipality: result.text,
        zip_code: zipcode,
        country: country,
      });
    }
  };

  const handleCreate = () => {
    if (props.farm) {
      updateFarm(farmPayload);
    } else {
      if (name && farmPayload.farm_name === '') {
        farmPayload.farm_name = name ?? '';
        setSkip(false);
      }
      createFarm(farmPayload);
      setFarmPayload({
        farm_name: '',
        customer_id: '',
        active: true,
        address: '',
        address_other: '',
        zip_code: '',
        municipality: '',
        country: '',
      });
    }
    if (onClose) {
      dispatch(deleteName());
      onClose();
    }
  };

  useEffect(() => {
    if (
      name &&
      isSuccess &&
      !isLoadingFarms &&
      isSuccessFarm &&
      props.onCloseFromPlotCreation &&
      farms
    ) {
      const filteredFarms = farms.filter((farm) => farm.farm_name === name);
      if (filteredFarms[0]) {
        props.onCloseFromPlotCreation(filteredFarms[0] as FarmData);
      }
    }
  }, [farms]);

  useEffect(() => {
    if (isError || isUpdateError) {
      setIsSnackbarError(true);
    }
    if (isSuccess || isUpdateSuccess) {
      setSnackBarSuccess(true);
    }
  }, [isError, isSuccess, isUpdateError, isUpdateSuccess]);

  useEffect(() => {
    if ((farmPayload.farm_name || name) && farmPayload.customer_id) {
      setIsDisabled(false);
    }
  }, [farmPayload]);

  const centerValue = () => {
    if (centers && customers) {
      const selectedCustomers = customers.find(
        (customer: CustomerData) => customer.solution_code === farmPayload.customer_id
      );
      return (
        selectedCustomers &&
        centers?.find((cntr: Center) => cntr.icv_center === selectedCustomers.icv_center)
      );
    }
  };

  useEffect(() => {
    if (!center && farmPayload && farmPayload.customer_id) {
      setCenter(centerValue());
    }
  }, [centers, center, farmPayload]);

  return (
    <>
      <Snackbar
        open={isSnackbarError}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarError(false)}
      >
        <Alert onClose={() => setIsSnackbarError(false)} severity='error' sx={{ width: '100%' }}>
          {t('error.creation.farm')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isSnackBarSuccess}
        autoHideDuration={6000}
        onClose={() => setSnackBarSuccess(false)}
      >
        <Alert onClose={() => setSnackBarSuccess(false)} severity='success' sx={{ width: '100%' }}>
          {t('success.creation.farm')}
        </Alert>
      </Snackbar>
      <Dialog fullWidth maxWidth='sm' scroll='paper' onClose={onClose} open={isOpen}>
        <DialogTitle>{t('dialog.farm.creation.title')}</DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={2}>
            {centers && (
              <Autocomplete
                id='tags-outlined'
                options={centers ?? []}
                disableClearable
                getOptionLabel={(option) => option.icv_center}
                filterSelectedOptions
                sx={{ width: 300 }}
                onChange={(event, value) => {
                  value && setCenter(value);
                }}
                value={center}
                renderInput={(params) => <TextField {...params} label={t('center')} />}
              />
            )}

            {customers && center && (
              <Autocomplete
                disabled={!center}
                options={
                  customers?.filter((customer) => customer.icv_center === center?.icv_center) || []
                }
                getOptionLabel={(option) => getCustomerDisplayName(option)}
                value={customers?.find(
                  (customer) => customer.solution_code === farmPayload.customer_id
                )}
                onChange={(event, value) => {
                  setFarmPayload({ ...farmPayload, customer_id: value?.solution_code || '' });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('dialog.farm.creation.customer')}
                    variant='outlined'
                  />
                )}
              />
            )}
            <FormControl>
              <InputLabel>{t('dialog.farm.creation.name')}</InputLabel>
              <Input
                onChange={(e) => {
                  dispatch(setName(e.target.value));
                  setFarmPayload({
                    ...farmPayload,
                    farm_name: e.target.value,
                  });
                }}
                value={farmPayload.farm_name}
              />
            </FormControl>
            <FormControl>
              <InputLabel>{t('dialog.farm.creation.adress')}</InputLabel>
              <Input
                onChange={(e) =>
                  setFarmPayload({
                    ...farmPayload,
                    address: e.target.value,
                  })
                }
                value={farmPayload.address}
              />
            </FormControl>
            <FormControl>
              <InputLabel>{t('dialog.farm.creation.adress_other')}</InputLabel>
              <Input
                onChange={(e) =>
                  setFarmPayload({
                    ...farmPayload,
                    address_other: e.target.value,
                  })
                }
                value={farmPayload.address_other}
              />
            </FormControl>
            <FormControl fullWidth={false}>
              <MatGeocoder
                inputPlaceholder={t('town') ?? ''}
                accessToken={MAPBOX_TOKEN}
                onSelect={setAddressInformation}
                showLoader={true}
                {...geocoderApiOptions}
              />
            </FormControl>
            <FormControl>
              <InputLabel>{t('dialog.farm.creation.zip_code')}</InputLabel>
              <Input disabled value={farmPayload.zip_code} />
            </FormControl>
            <FormControl>
              <InputLabel>{t('dialog.farm.creation.municipality')}</InputLabel>
              <Input disabled value={farmPayload.municipality} />
            </FormControl>
            <FormControl>
              <InputLabel>{t('dialog.farm.creation.country')}</InputLabel>
              <Input disabled value={farmPayload.country} />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={farmPayload.active}
                  onClick={() =>
                    setFarmPayload({
                      ...farmPayload,
                      active: !farmPayload.active,
                    })
                  }
                />
              }
              label={t('dialog.farm.creation.active')}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(deleteName());
              onClose && onClose();
              props.onCloseFromPlotCreation && props.onCloseFromPlotCreation(undefined, true);
            }}
            color='primary'
          >
            {t('button.cancel')}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() => handleCreate()}
            variant='contained'
            color='primary'
          >
            {t('button.validate')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FarmDialog;
