import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import type { FeatureCollection } from '@turf/turf';
import { FarmData } from 'library/models/farm';
import { PlotData } from 'library/models/plot';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MatGeocoder from 'react-mui-mapbox-geocoder';
import { useDispatch, useSelector } from 'react-redux';
import NumericFormatCustom from '../../../components/text-field/TextField';
import {
  useGetCentersQuery,
  useGetCustomersByIcvCenterQuery,
} from '../../../customers/customerApi';
import FarmDialog from '../../../farms/components/FarmDialog';
import { useGetFarmsQuery } from '../../../farms/store/famsApi';
import { setName } from '../../../farms/store/slice';
import { useGetGrapeVarietiesQuery } from '../../../grapeVariety/store/grapeVarietyApi';
import { RootState } from '../../../redux/store';
import { useGetSectorsQuery } from '../../../sector/store/apiSector';
import { useCheckExistQuery } from '../../store/plotApi';
import { utils } from '../../utils/utils';
import DisplayLastPlots from '../DisplayLastPlots';

export type IsChecked = {
  internal_code: boolean;
  external_code: boolean;
};

type Props = {
  onPlotPayloadChange: (plot: PlotData) => void;
  plotPayload: PlotData;
  setIsvalid: (data: IsChecked | undefined) => void;
};

const PlotUpdateStepOne = ({
  onPlotPayloadChange,
  plotPayload,
  setIsvalid,
}: Props): React.ReactElement => {
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name } = useSelector((state: RootState) => state.createFarmSlice);
  const { data: grapeVariety } = useGetGrapeVarietiesQuery();
  const { data: farms } = useGetFarmsQuery();
  const { data: centers } = useGetCentersQuery();
  const { data: sectors } = useGetSectorsQuery();
  const center = plotPayload?.customer.icv_center ?? '';
  const { data: customers } = useGetCustomersByIcvCenterQuery(center);
  const [defaultCode, setDefaultCode] = useState('');

  const [maxLengthFilter, setMaxLengthFilter] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setDefaultCode(plotPayload.external_plot_code);
  }, [true]);

  const { data: isChecked } = useCheckExistQuery(
    {
      vintage: plotPayload.vintage.toString(),
      internal_plot_code: plotPayload.internal_plot_code ?? '',
      external_plot_code: plotPayload.external_plot_code,
      customer_id: plotPayload?.solution_code ?? '',
    },
    {
      skip: false,
    }
  );

  useEffect(() => {
    setIsvalid(isChecked);
  }, [isChecked]);

  const returnYearsOptions = utils.returnYearsOptions();

  const geocoderApiOptions = {
    country: 'fr',
    types: 'place',
  };

  const addFarmButton = () => {
    return (
      <Button onClick={() => setOpen(true)} variant='outlined' startIcon={<AddCircleOutlineIcon />}>
        {t('addFarm')}
      </Button>
    );
  };

  const handleClose = (farm: FarmData | undefined, cancel: boolean) => {
    if (cancel) {
      setOpen(false);
    }
    if (farm) {
      onPlotPayloadChange({ ...plotPayload, farm_id: farm.farm_id ?? '' });
    }
    setOpen(false);
  };

  // TODO: find type
  const setAdressInformation = async (result: MapboxGeocoder.Result) => {
    result.text;
    const longitude = result.geometry.coordinates[0];
    const latitude = result.geometry.coordinates[1];
    const mapboxReverseGeocodingResult = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&access_token=${MAPBOX_TOKEN}`
    );
    const mapboxReverseGeocodingResultData: FeatureCollection =
      await mapboxReverseGeocodingResult.json();
    if (mapboxReverseGeocodingResultData.features.length > 0) {
      const feature = mapboxReverseGeocodingResultData.features[0] as MapboxGeocoder.Result;
      const zipcode = feature.context.find((context) => context.id.includes('postcode'))?.text;
      const country = feature.context
        .find((context) => context.id.includes('country'))
        ?.short_code?.toUpperCase();

      onPlotPayloadChange({
        ...plotPayload,
        municipality: result.text,
        zip_code: zipcode,
        country: country,
      });
    }
  };

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const dateFormater = (date: Date) => date.toLocaleDateString('fr-FR', options);

  return (
    <Container>
      {open && (
        <FarmDialog
          onCloseFromPlotCreation={(farm, cancel) => handleClose(farm, cancel ?? false)}
          isOpen={open}
          centerProps={centers?.find((c) => c.icv_center === center)}
          customerId={plotPayload?.solution_code ?? ''}
        />
      )}
      <Container>
        <Typography m={4} variant='h4'>
          {t('generalInformation')}
        </Typography>
        <Stack direction={'row'} flexWrap='wrap' width={'100%'} justifyContent={'space-between'}>
          <FormControl sx={{ m: 1, width: '25%', minWidth: '180px' }}>
            <Autocomplete
              disablePortal
              disabled
              id='auto-complete-centre'
              options={centers ?? []}
              getOptionLabel={(option) => option.icv_center}
              value={centers?.find((c) => c.icv_center === center) ?? null}
              filterOptions={(options, state) => {
                // Personnalisation de la fonction de recherche
                const inputValue = state.inputValue.toLowerCase();
                return options.filter((option) =>
                  option.icv_center.toLowerCase().includes(inputValue)
                );
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label='Centre*' />}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '25%', minWidth: '180px' }}>
            <Autocomplete
              disabled={!center}
              disablePortal
              value={customers?.find((c) => c.solution_code === plotPayload?.solution_code) ?? null}
              id='auto-complete-clients'
              options={customers ?? []}
              getOptionLabel={(option) =>
                option.solution_code +
                ' - ' +
                option.customer_name +
                ' - ' +
                option.solution_site_name
              }
              sx={{ width: 300 }}
              onChange={(e, value) =>
                onPlotPayloadChange({ ...plotPayload, solution_code: value?.solution_code ?? '' })
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.customer_id}>
                    {option.solution_code +
                      ' - ' +
                      option.customer_name +
                      ' - ' +
                      option.solution_site_name}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label={t('customers') + '*'} />}
              filterOptions={(options, state) => {
                // Personnalisation de la fonction de recherche
                const inputValue = state.inputValue.toLowerCase();
                return options.filter(
                  (option) =>
                    (option.active && option.customer_name.toLowerCase().includes(inputValue)) ||
                    option.solution_code.toLowerCase().includes(inputValue) ||
                    (option.solution_site_name &&
                      option.solution_site_name.toLowerCase().includes(inputValue))
                );
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '25%', minWidth: '180px' }}>
            <Autocomplete
              disablePortal
              disabled={!plotPayload?.solution_code}
              id='auto-complete-exploitation'
              options={
                farms ? farms.filter((farm) => farm.customer_id === plotPayload?.solution_code) : []
              }
              noOptionsText={addFarmButton()}
              renderOption={(props, option, index) => {
                return (
                  <>
                    <li {...props} key={option.farm_id}>
                      {option.farm_name}
                    </li>
                    {index.index + 1 === maxLengthFilter && (
                      <li style={{ marginBottom: '10px' }}>{addFarmButton()}</li>
                    )}
                  </>
                );
              }}
              value={farms?.find((f) => f.farm_id === plotPayload?.farm_id) ?? null}
              getOptionLabel={(option) => option.farm_name ?? ''}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();
                dispatch(setName(inputValue));
                const optionsFiltered = options.filter((option) =>
                  option.farm_name.toLowerCase().includes(name ?? inputValue)
                );
                setMaxLengthFilter(optionsFiltered.length);
                return optionsFiltered;
              }}
              sx={{ width: 300 }}
              onChange={(e, value) =>
                onPlotPayloadChange({ ...plotPayload, farm_id: value?.farm_id ?? '' })
              }
              renderInput={(params) => <TextField {...params} label={t('farms') + '*'} />}
            />
          </FormControl>
        </Stack>
      </Container>
      <Container>
        <Typography m={4} variant='h4'>
          {t('detailsInformations')}
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={8} width={'100%'}>
          <Stack direction='column' spacing={4} width={'40%'}>
            <FormControl>
              <TextField
                label={t('solutionCode') + '*'}
                value={plotPayload?.internal_plot_code ?? ''}
                disabled
              />
            </FormControl>
            <FormControl>
              <TextField
                label={t('plotName')}
                value={plotPayload?.external_plot_name ?? ''}
                onChange={(e) =>
                  onPlotPayloadChange({ ...plotPayload, external_plot_name: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                disablePortal
                id='auto-complete-grapeVariety'
                options={grapeVariety ?? []}
                value={
                  grapeVariety?.find((c) => c.cepage_code === plotPayload?.cepage_code) ?? null
                }
                getOptionLabel={(option) => option.cepage_complete_name}
                fullWidth
                onChange={(e, value) =>
                  onPlotPayloadChange({ ...plotPayload, cepage_code: value?.cepage_code ?? '' })
                }
                renderInput={(params) => <TextField {...params} label={t('cepage') + '*'} />}
              />
            </FormControl>
            <FormControl>
              <InputLabel id='millesim'>{t('millesim') + '*'}</InputLabel>
              {returnYearsOptions.length > 0 && (
                <Select
                  value={plotPayload?.vintage ?? ''}
                  error={!!plotPayload.vintage && !returnYearsOptions}
                  onChange={(e) =>
                    onPlotPayloadChange({ ...plotPayload, vintage: Number(e.target.value) })
                  }
                >
                  {returnYearsOptions.map((year) => {
                    return (
                      <MenuItem value={year} key={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </FormControl>
          </Stack>
          <Stack direction='column' spacing={4} width={'40%'}>
            <FormControl sx={{ m: 1, width: '35%', minWidth: '180px' }}>
              <TextField
                error={isChecked?.external_code && plotPayload?.external_plot_code !== defaultCode}
                label={t('externalCode') + '*'}
                value={plotPayload?.external_plot_code ?? ''}
                onChange={(e) =>
                  onPlotPayloadChange({ ...plotPayload, external_plot_code: e.target.value })
                }
              />
              {isChecked?.external_code && plotPayload?.external_plot_code !== defaultCode && (
                <Typography mt={1} color='red' align='right' fontSize={9}>
                  {t('code.already.used')}
                </Typography>
              )}
              <DisplayLastPlots
                value='external_plot_code'
                customerId={plotPayload.solution_code}
                vintage={plotPayload.vintage}
              />
            </FormControl>
            <FormControl>
              <TextField
                label={t('surface')}
                value={plotPayload.surface_ha}
                onChange={(e) =>
                  onPlotPayloadChange({
                    ...plotPayload,
                    surface_ha: parseFloat(e.target.value),
                  })
                }
                name='numberformat'
                id='formatted-numberformat-input'
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                disablePortal
                id='auto-complete-sectors'
                options={sectors ?? []}
                getOptionLabel={(option) => option.sector_name}
                value={sectors?.find((s) => s.sector_code === plotPayload?.sector_code) ?? null}
                fullWidth
                onChange={(e, value) =>
                  onPlotPayloadChange({ ...plotPayload, sector_code: value?.sector_code ?? '' })
                }
                renderInput={(params) => <TextField {...params} label={t('sector')} />}
              />
            </FormControl>
            <FormControl fullWidth={false}>
              <MatGeocoder
                inputPlaceholder={t('town') ?? ''}
                accessToken={MAPBOX_TOKEN}
                onSelect={setAdressInformation}
                showLoader={true}
                {...geocoderApiOptions}
              />
              <Typography variant='body1' mt={2} color='text.secondary' align='right'>
                {plotPayload?.municipality &&
                  `${plotPayload?.zip_code ?? ''} ${plotPayload?.municipality} ${
                    plotPayload?.country
                  }`}
              </Typography>
            </FormControl>
          </Stack>
        </Stack>
      </Container>
      <Container>
        <Typography m={2}>
          Crée le {dateFormater(new Date(plotPayload.creation_date))} par{' '}
          {plotPayload.creator_user.first_name} {plotPayload.creator_user.family_name}
        </Typography>
        {plotPayload.modification_date &&
          plotPayload.modifier_user &&
          plotPayload.modification_date && (
            <Typography m={2}>
              Modifiée le {dateFormater(new Date(plotPayload.modification_date))} par{' '}
              {plotPayload.modifier_user.first_name} {plotPayload.modifier_user.family_name}
            </Typography>
          )}
      </Container>
    </Container>
  );
};
export default PlotUpdateStepOne;
