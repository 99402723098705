export const plotFr = {
  //Information messages:
  'button.validate': 'Valider',
  'button.cancel': 'Annuler',
  'plot.selected.renew': 'parcelles selectionnées seront reconduites vers le millésime',
  select: 'Sélectionner',
  'plot.button.yes': 'Oui',
  'plot.button.no': 'Non',
  'plot.list.title': 'Liste des parcelles',
  'plot.creation': "Création d'une parcelle",
  'plots.renew.title': 'Reconduction des parcelles',
  'plots.print.title': 'Impression des étiquettes',
  'information.plot.creation': 'Création de la parcelle réussie',
  'information.parcelle.update.information': 'Informations de la parcelle',
  'information.parcelle.update.refAndlocaliation': 'Référentiel et localisation',
  'search.by': 'Rechercher par: nom, code, cépage, etc...',
  'center:': 'Centre:',
  farms: 'Exploitations',
  'millesime.create': 'Millésime',

  'information.plot.total.renew': 'parcelles seront traitées au total:',
  'information.plot.will.renew': 'parcelles vont être reconduites au millésime ',
  'information.plot.not.renew': 'parcelles existantes en',
  'information.plot.not.renew.end': ' seront maintenues inchangées.',

  'information.plot.renew': 'parcelles ont bien été reconduites au millésime',
  'update.plots': 'Mettre à jour la parcelle',
  'error.parcelle.update': 'Erreur, impossible de mettre à jour la parcelle',

  'plot.update.confirmeMessage.WAIT':
    'Vous avez modifié des informations agronomiques (cépages, code externe, etc...) est-ce que vous faites cette modification suite a une intervention sur votre parcelle (arrachage, etc...) ?',
  'plot.update.confirmeMessage.WAIT.button.YES': "Oui, c'est suite à une intervention",
  'plot.update.confirmeMessage.WAIT.button.NO': "Non, c'est suite à une erreur de saisie",

  'plot.update.confirmeMessage.NO': 'Vous êtes sûr des modifications réalisées ?',
  'plot.update.confirmeMessage.YES.title': "Oui, c'est suite à une intervention",
  'plot.update.confirmeMessage.YES':
    "Ces modifications supposent la création d'une nouvelle parcelle qui ne sera pas liée aux millésimes antérieurs. Êtes-vous d'accord ?",

  'plot.update.confirmeMessage.NO.title': "Non, c'est suite à une erreur de saisie",
  'information.plot.update': 'Modification de la parcelle réussie',
  'button.close.return.plotsList': 'Retour à la liste des parcelles',

  'print.dialog.filter.by': 'Trier les étiquettes par :',
  //Dialog print:
  'dialog.print.jump.page': 'Saut de pages',
  'print.dialog.nb.etiquette': "Nombre d'étiquettes par parcelle",
  'print.dialog.title': 'Imprimer les parcelles',
  'dialog.print.button.add.filter': '+ Ajouter un autre tri',
  'dialog.print.button.print': 'Imprimer',
  'print.dialog.nb.etiquette.text': 'Sauter de page à chaque ',
  'dialog.print.jump.page.end': 'différent(e)',
  farm_name: 'Exploitation',
  sector_code: 'Secteur',
  municipality: 'Commune',
  external_plot_code: 'Code parcelle client',
  internal_plot_code: 'Code parcelle interne',
  solution_code: 'Code client solution',

  //Table headers:
  'table.header.plot.name': 'Code - Nom (Surface (ha))',
  'table.header.plot.customer': 'Code interne - Nom client - Site',
  'table.header.plot.farm': 'Exploitant',
  'table.header.plot.cepage_name': 'Cépage',

  //Button messages:
  'button.add.newPlot': 'Créer une nouvelle parcelle',
  'button.renew.plot': ' Reconduire les parcelles',
  'button.print.plot': 'Imprimer les parcelles',
  'button.close': 'Fermer',
  'success.parcelle.update': 'Modification réussie',
};
